function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { HighlightedSection } from '@components/HighlightedSection';
import { Section } from '@components/Section';
import VideoCard from '@components/videoCard';
import { HomeFeedResultInterface, VideoComponentInterface } from '@customTypes/index';
import { useDeviceInfo } from '@hooks/react-use';
import { SkyEsportsTournamentTitleEnum } from '@layouts/skyEsportsChampionship/components/skyEsportsVideosSection';
import { useEffect, useMemo, useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
type Iprops = HomeFeedResultInterface;
const MIN_VIDEO_REQUIRED_TO_SHOW_SECTION = 4;
const modularCount = (count: number) => {
  return count - count % 4;
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1hlwcnv",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));}"
} : {
  name: "oa238o-HomePageVideosSection",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));};label:HomePageVideosSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1hlwcnv",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));}"
} : {
  name: "oa238o-HomePageVideosSection",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));};label:HomePageVideosSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1hlwcnv",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));}"
} : {
  name: "oa238o-HomePageVideosSection",
  styles: "display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:0.5rem;overflow:hidden;@media (min-width: 768px){grid-template-columns:repeat(4, minmax(0, 1fr));};label:HomePageVideosSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const HomePageVideosSection = ({
  result,
  v_position,
  sectionBeforeHighlighted
}: {
  result: Iprops;
  v_position: number;
  sectionBeforeHighlighted: string;
}) => {
  const {
    screen_isMobile,
    screen_isDesktop
  } = useDeviceInfo();
  const [maxCount, setMaxCount] = useState(4);
  const isGearingUpForFinalSection = result?.title?.toLowerCase().includes(SkyEsportsTournamentTitleEnum.GEARING_UP_FOR_FINALS.toLowerCase());
  const contentLength = result?.content?.length;
  useEffect(() => {
    const NO_OF_VIDOES_TO_SHOW = isGearingUpForFinalSection ? contentLength > 4 //only have to show 4 elements irrespective of items coming from backend
    ? 4 : contentLength : screen_isMobile ? 4 : modularCount(result?.content?.length ?? 0);
    setMaxCount(NO_OF_VIDOES_TO_SHOW);
  }, [result?.content?.length, screen_isMobile]);
  const handleRoute = () => {
    if (result?.title?.includes('Trending Videos in BGMI')) {
      return;
    } else if (result?.title?.includes('Trending Now')) {
      return '/trending';
    } else if (result?.title?.includes('Most Watched Streams')) {
      return '/streams';
    } else if (result?.title?.includes('BGMI')) {
      return '/categories/battlegrounds-mobile-india';
    } else if (result?.title?.includes('Free Fire')) {
      return '/categories/free-fire';
    } else if (result?.title?.includes('Valorant')) {
      return '/categories/valorant';
    } else if (result?.title?.includes('GTA V')) {
      return '/categories/gta-5';
    } else if (result?.title?.includes('Trending Videos')) {
      return '/trending-videos';
    } else if (result?.title?.includes('Videos')) {
      return '/videos-in-focus';
    } else if (isGearingUpForFinalSection && contentLength > 4) {
      return '/events/bgmi-skyesports-championship/tournament-videos';
    }
  };
  const VideosComponent = useMemo(() => {
    return result.content.slice(0, maxCount).map((video, index) => {
      const {
        data,
        analytics
      } = (video.component as VideoComponentInterface);
      return _jsx(VideoCard, {
        src: data?.playback_url?.playback,
        stream_id: data?.stream_uid?.data,
        stream_title: data?.stream_title?.data,
        is_live: data?.is_live,
        streamer_avatar_src: data?.streamer_image?.data,
        streamer_id: data?.streamer_uid?.data,
        streamer_name: data?.streamer_name?.data,
        thumbnail_src: data?.thumbnail?.data,
        gametitle: data?.stream_tags[0]?.data,
        gameUID: analytics.category_id,
        views: data?.view_count?.data,
        uploaded_time: data?.uploaded_time?.data,
        duration: data?.duration?.data,
        source_component_data: {
          source_component_name: result?.title ?? '',
          source_component_type: 'video grid',
          source_component_v_position: v_position + 1,
          //carousel will always be at first position
          source_component_h_position: 1,
          source_component_size: maxCount,
          source_position_in_source_component: index + 1,
          source_view_all_in_source_component: 'no',
          source_ingredient_name: result?.title,
          source_ingredient_position: v_position,
          source_ingredient_size: result?.content?.length,
          source_position_in_ingredient: index + 1
        }
      }, data?.stream_uid?.data);
    });
  }, [maxCount, result.content]);
  const HighlightedVideosComponent = useMemo(() => {
    return result.content.slice(0, maxCount).map((video, index) => {
      const {
        data,
        analytics
      } = (video.component as VideoComponentInterface);
      return _jsx(VideoCard, {
        src: data?.playback_url?.playback,
        stream_id: data?.stream_uid?.data,
        stream_title: data?.stream_title?.data,
        is_live: data?.is_live,
        streamer_avatar_src: data?.streamer_image?.data,
        streamer_id: data?.streamer_uid?.data,
        streamer_name: data?.streamer_name?.data,
        thumbnail_src: data?.thumbnail?.data,
        gametitle: data?.stream_tags[0]?.data,
        gameUID: analytics.category_id,
        views: data?.view_count?.data,
        uploaded_time: data?.uploaded_time?.data,
        duration: data?.duration?.data,
        source_component_data: {
          source_component_name: result?.title ?? '',
          source_component_type: 'video grid',
          source_component_v_position: v_position + 1,
          //carousel will always be at first position
          source_component_h_position: 1,
          source_component_size: maxCount,
          source_component_highlighted: 'yes',
          source_position_in_source_component: index + 1,
          source_view_all_in_source_component: 'no',
          source_ingredient_name: result?.title,
          source_ingredient_position: v_position,
          source_ingredient_size: result?.content?.length,
          source_position_in_ingredient: index + 1
        }
      }, data?.stream_uid?.data);
    });
  }, [maxCount, result.content]);
  if (result?.content?.length < MIN_VIDEO_REQUIRED_TO_SHOW_SECTION) return null;
  return _jsxs(_Fragment, {
    children: [result.highlightColor && screen_isDesktop ? _jsx(HighlightedSection, {
      title: result.title,
      thumbnail: result.thumbnail,
      highlightColor: result.highlightColor,
      subTitle: "See More",
      subTitleLink: handleRoute(),
      source_component_data: {
        source_component_name: result?.title ?? '',
        source_component_type: 'video grid',
        source_component_v_position: v_position + 1,
        //carousel will always be at first position
        source_component_h_position: 1,
        source_component_size: maxCount,
        source_view_all_in_source_component: 'yes',
        source_ingredient_name: result?.title,
        source_ingredient_position: v_position,
        source_ingredient_size: result?.content?.length
      },
      children: _jsx("div", {
        css: _ref,
        children: HighlightedVideosComponent
      })
    }) : screen_isDesktop ? result.title != 'Trending Now' ? _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: handleRoute(),
      sectionBeforeHighlighted: sectionBeforeHighlighted,
      source_component_data: {
        source_component_name: result?.title ?? '',
        source_component_type: 'video grid',
        source_component_v_position: v_position + 1,
        //carousel will always be at first position
        source_component_h_position: 1,
        source_component_size: maxCount,
        source_view_all_in_source_component: 'yes',
        source_ingredient_name: result?.title,
        source_ingredient_position: v_position,
        source_ingredient_size: result?.content?.length
      },
      children: _jsx("div", {
        css: _ref2,
        children: VideosComponent
      })
    }) : null : _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: handleRoute(),
      sectionBeforeHighlighted: sectionBeforeHighlighted,
      source_component_data: {
        source_component_name: result?.title ?? '',
        source_component_type: 'video grid',
        source_component_v_position: v_position + 1,
        //carousel will always be at first position
        source_component_h_position: 1,
        source_component_size: maxCount,
        source_view_all_in_source_component: 'yes',
        source_ingredient_name: result?.title,
        source_ingredient_position: v_position,
        source_ingredient_size: result?.content?.length
      },
      children: _jsx("div", {
        css: _ref3,
        children: VideosComponent
      })
    }), v_position % 4 === 0 && _jsx("div", {
      id: `ins_banner_container_b${`${v_position + 5}`}`
    })]
  });
};
export default HomePageVideosSection;